import inView from 'in-view';
import parallax from 'parallax';
import vars from 'variables';
import { $, $$ } from 'query-selector';

export default () => {
  function inAnims() {
    const els = $$('.u-anim--in');
    if (!els) return;

    let count = 0;

    inView(els, (el, visible) => {
      if (visible) {
        el.classList.add('u-anim--play');
        if (count > 0 && !el.classList.contains('u-anim--no-delay'))
          el.classList.add('u-anim--delay-' + count);

        count++;

        // Wait 1 frame
        window.requestAnimationFrame(() => {
          count--;
        });
      }
    });
  }

  inAnims();

  function parallaxAnims() {
    const els = $$('.js-parallax');
    if (!els) return;

    const plxEls = [];

    for (const el of els) {
      const offset = parseInt(el.dataset.offset);

      plxEls.push({
        reference: el,
        target: el,
        transform: [
          {
            type: 'translateY',
            value: [0, offset],
            unit: 'rem'
          }
        ]
      });
    }

    parallax(plxEls);
  }

  parallaxAnims();
};
