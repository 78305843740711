export default callback => {
  let ticking = false;

  function scroll() {
    if (ticking) return;

    window.requestAnimationFrame(() => {
      callback();
      ticking = false;
    });

    ticking = true;
  }

  window.addEventListener('scroll', scroll);
};
