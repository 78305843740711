export default (els, callback, options) => {
  // Set defaults
  options = Object.assign(
    {
      offset: 0,
      once: true
    },
    options
  );

  // Intersection Observer
  const intersectionObserverSupport =
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
    'isIntersecting' in window.IntersectionObserverEntry.prototype;

  if (intersectionObserverSupport) {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting === true || entry.intersectionRatio > 0) {
            callback(entry.target, true);
            if (options.once) observer.unobserve(entry.target);
          } else {
            callback(entry.target, false);
          }
        });
      },
      {
        rootMargin: `0% 0% ${-options.offset * 100}%`
      }
    );

    for (const el of els) {
      observer.observe(el);
    }
  } else {
    for (const el of els) {
      // Fallback
      let alreadyInView = false;

      function inView(force = false) {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (
          rect.top <= windowHeight * (1 - options.offset) &&
          rect.bottom >= windowHeight * options.offset
        ) {
          if (force || !alreadyInView) {
            alreadyInView = true;
            callback(el, true);

            if (options.once) {
              window.removeEventListener('scroll', onScroll);
              window.removeEventListener('resize', onResize);
              window.removeEventListener('orientationchange', onResize);
            }
          }
        } else {
          if (force || alreadyInView) {
            alreadyInView = false;
            callback(el, false);
          }
        }
      }

      // On load
      inView(true);

      // On scroll
      let ticking = false;

      function onScroll() {
        if (ticking) return;

        window.requestAnimationFrame(() => {
          inView();
          ticking = false;
        });

        ticking = true;
      }

      window.addEventListener('scroll', onScroll);

      // On resize
      const delay = 250;
      let lastResize = 0;

      function onResize() {
        const now = new Date().getTime();
        if (now - lastResize < delay) return;

        lastResize = now;
        inView();
      }

      window.addEventListener('resize', onResize);
      window.addEventListener('orientationchange', onResize);
    }
  }
};
