import transitionEnd from 'transition-end';
import { $, $$ } from 'query-selector';

export default () => {
  const filterList = $('.js-filter-list');
  const buttons = $$('.js-filter-button');
  const projectsList = $('.js-projects-list');
  const items = $$('.js-projects-item');
  if (!filterList || !buttons || !items) return;

  const activeButtonClass = 'o-filter-button--active';
  const fadeItemClass = 'c-project-teaser--fade';
  const hiddenItemClass = 'c-project-teaser--hidden';

  let isAnimating = false;

  async function filterItems(category) {
    isAnimating = true;

    const anims = [];

    // Fade out all items
    for (const el of items) {
      if (el.classList.contains(hiddenItemClass)) continue;

      el.classList.add(fadeItemClass);
      anims.push(transitionEnd(el, 'opacity'));
    }

    await Promise.all(anims);

    // Filter items
    for (const el of items) {
      if (category === 'all') {
        el.classList.remove(hiddenItemClass);
      } else {
        if (el.dataset.category.indexOf(category) !== -1) {
          el.classList.remove(hiddenItemClass);
        } else {
          el.classList.add(hiddenItemClass);
        }
      }
    }

    // Apply changes
    const forceLayout = projectsList.offsetWidth;

    // Fade in not hidden items
    for (const el of items) {
      if (!el.classList.contains(hiddenItemClass))
        el.classList.remove(fadeItemClass);
    }

    isAnimating = false;
  }

  function removeButtonActiveClass() {
    for (const el of buttons) {
      el.classList.remove(activeButtonClass);
    }
  }

  for (const el of buttons) {
    el.addEventListener('click', () => {
      // Prevent multiple operations at once
      if (isAnimating || el.classList.contains(activeButtonClass)) return;

      // Set button active class
      removeButtonActiveClass();
      el.classList.add(activeButtonClass);

      // Filter items
      filterItems(el.dataset.category || 'all');

      // Set url param
      let url = filterList.dataset.url;

      if (el.dataset.category) {
        url += '/' + filterList.dataset.param + ':' + el.dataset.category;
      }

      window.history.replaceState(null, null, url);
    });
  }

  for (const el of items) {
    // Add link behavious
    const link = $('a', el);

    link.addEventListener('click', e => {
      // Find active filter button and get category
      for (const el of buttons) {
        if (el.classList.contains(activeButtonClass) && el.dataset.category) {
          link.href +=
            '/' + filterList.dataset.param + ':' + el.dataset.category;
        }
      }
    });

    // Fade in not hidden items
    if (!el.classList.contains(hiddenItemClass))
      el.classList.remove(fadeItemClass);
  }
};
